import "../styles/index.css";
import "../src/main.css";
import { Router } from "next/router";
import { appWithTranslation } from "next-i18next";
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import { ThemeProvider } from "styled-components";
import { CampaignProvider } from "contexts/campaign-context";
import { UserProvider } from "contexts/user-context";
import { GlobalStyles, theme } from "theme";
import "sweetalert2/src/sweetalert2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

type Props = {
   children: React.ReactNode;
   theme: any;
};
//test

const ThemeProviderFixed = ThemeProvider as unknown as React.FC<Props>;

function MyApp({ Component, pageProps }) {
   return (
      <UserProvider>
         <CampaignProvider>
            <ThemeProviderFixed theme={theme}>
               <GlobalStyles />
               {/* <NextNProgress
                  color="#7211D4"
                  startPosition={0.4}
                  stopDelayMs={200}
                  height={3.2}
                  showOnShallow={true}
               /> */}
               <Component {...pageProps} />
            </ThemeProviderFixed>
         </CampaignProvider>
      </UserProvider>
   );
}

export default appWithTranslation(MyApp);
