const colors = {
   main: "#7211D4",
   primary: "#7211D4",
   white: "#FFFFFF",
   mainText: "#010947",
   headingText: "#000000",
   grey: "#A7A7A7",
   grey02: "#606C84",
   background: "#FCFBFF",
   black: "#020202",
   secondary: "#010947",
   error: "#E91C24",
};

export { colors };
