import { createGlobalStyle } from "styled-components";
import { fontFamily } from "./fonts";
import { reset } from "./reset";

export const GlobalStyles = createGlobalStyle`

${reset}
${fontFamily}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

#nprogress .bar {
  background: #7211D4;
}
#nprogress .peg {
  box-shadow: 0 0 10px #7211D4, 0 0 5px #7211D4;
}
#nprogress .spinner-icon {
  border-top-color: #7211D4;
  border-left-color: #7211D4;
}
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "helvetica, sans-serif";
  }

  body {
    transition: all 0.50s linear;
    background-color: #fcfbff;
  }
button.MuiBox-root{
  color: red;
}
  button.MuiBox-root::before {
    background-color: #7211d4
    color:#7211d4;
    font-family: "Montserrat";
  }

  .paginator {
   display: flex;
   align-items: center;
   gap: 1em;
   font-weight: 600;

   li {
     cursor: pointer;

     &.active {
     color: #fff;
     background-color: #7211d4;
     padding:.5em;
     cursor: pointer;
   }
   }


   button {
     background: transparent;
     border: none;
     outline: none;
     display: flex;
     cursor: pointer;
    font-weight: 600;
    align-items: center;
    gap: 1em;
&.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

   }
}

`;
