import { createContext, useReducer, useContext } from "react";
import type { ReactNode } from "react";
type ActionType = "set_cam_data" | "set_card_token";
type ActionPayload = any;
type Action = {
   [key: string]: ActionType | ActionPayload;
};
const defaultState = {
   step_one: {
      campaign_name: "",
      campaign_type: "",
      url: "",
      enable_recurring: "sass",
      currency: "",
      interval: "",
      trial_period: 0,
   },
   step_two: {
      commission_type: "",
      commission_percent: 0,
      commission_amount: 0,
      payout_terms: "NET30",
      amount: 0,
   },
   loading: false,
};

export type State = typeof defaultState;
export type Dispatch = (action: Action) => void;

const CampaignContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function userReducer(state: State, action: Action): State {
   switch (action.type) {
      case "set_step_one":
         return {
            ...state,
            step_one: action.payload,
         };
      case "set_step_two":
         return {
            ...state,
            step_two: action.payload,
         };
      case "set_loading_true":
         return {
            ...state,
            loading: true,
         };
      case "set_loading_false":
         return {
            ...state,
            loading: false,
         };

      default:
         return state;
   }
}

export function CampaignProvider({ children }: { children: ReactNode }) {
   const [state, dispatch] = useReducer(userReducer, defaultState);
   return (
      <CampaignContext.Provider value={{ state, dispatch }}>{children}</CampaignContext.Provider>
   );
}

export function useFirstCampaign() {
   const context = useContext(CampaignContext);
   if (!context) throw new Error("useFirstCampaign must be used inside a CampaignProvider");
   return context;
}
