import { createContext, useReducer, useContext } from "react";
import type { ReactNode } from "react";
type ActionType = "set_user" | "set_card_token" | "set_token";
type ActionPayload = any;
type Action = {
   [key: string]: ActionType | ActionPayload;
};
const defaultState = {
   token: null,
   domain: "",
   firstname: "",
   lastname: "",
   email: "",
   password: "",
   cardToken: null,
};

export type State = typeof defaultState;
export type Dispatch = (action: Action) => void;

const UserContext = createContext<{ userState: State; dispatch: Dispatch } | undefined>(undefined);

function userReducer(state: State, action: Action): State {
   switch (action.type) {
      case "set_user":
         return {
            ...state,
            domain: action.payload.domain,
            firstname: action.payload.firstname,
            lastname: action.payload.lastname,
            email: action.payload.email,
            password: action.payload.password,
         };
      case "set_card_token":
         return {
            ...state,
            cardToken: action.payload,
         };

      case "set_token":
         return {
            ...state,
            token: action.payload,
         };

      default:
         return state;
   }
}

export function UserProvider({ children }: { children: ReactNode }) {
   const [userState, dispatch] = useReducer(userReducer, defaultState);
   return <UserContext.Provider value={{ userState, dispatch }}>{children}</UserContext.Provider>;
}

export function useThisUser() {
   const context = useContext(UserContext);
   if (!context) throw new Error("useCounter must be used inside a UserProvider");

   return context;
}
