import { css } from "styled-components";

const fontFamily = css`
  @font-face {
    font-family: "helvetica";
    src: url("assets/fonts/helvetica/HelveticaNowDisplay-Thin.ttf");
    font-weight: 200;
  }
  @font-face {
    font-family: "Mark OT";
    src: url("assets/fonts/helvetica/HelveticaNowDisplay-Light.ttf");
    font-weight: 300;
  }
  @font-face {
    font-family: "Mark OT";
    src: url("assets/fonts/helvetica/HelveticaNowDisplay-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Mark OT";
    src: url("assets/fonts/helvetica/HelveticaNowDisplay-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Mark OT";
    src: url("assets/fonts/helvetica/HelveticaNowDisplay-Bold.ttf");
    font-weight: 600;
  }
`;
// const fontFamily = css`
//   @font-face {
//     font-family: "Mark OT";
//     src: url("assets/fonts/Poppins/Poppins-Thin.ttf");
//     font-weight: 200;
//   }
//   @font-face {
//     font-family: "Mark OT";
//     src: url("assets/fonts/Poppins/Poppins-Light.ttf");
//     font-weight: 300;
//   }
//   @font-face {
//     font-family: "Mark OT";
//     src: url("assets/fonts/Poppins/Poppins-Regular.ttf");
//     font-weight: 400;
//   }
//   @font-face {
//     font-family: "Mark OT";
//     src: url("assets/fonts/Poppins/Poppins-Medium.ttf");
//     font-weight: 500;
//   }
//   @font-face {
//     font-family: "Mark OT";
//     src: url("assets/fonts/Poppins/Poppins-Bold.ttf");
//     font-weight: 600;
//   }
// `;

// export default fontFamily;


// const fontFamily = css`
//    @font-face {
//       font-family: ""poppins", sans-serif";
//       src: url("/assets/fonts/Gotham-Narrow/GothamNarrow-Thin.otf");
//       font-weight: 200;
//    }
//    @font-face {
//       font-family: ""poppins", sans-serif";
//       src: url("/assets/fonts/Gotham-Narrow/GothamNarrow-Light.otf");
//       font-weight: 300;
//    }
//    @font-face {
//       font-family: ""poppins", sans-serif";
//       src: url("/assets/fonts/Gotham-Narrow/GothamNarrow-Regular.otf");
//       font-weight: 400;
//    }
//    @font-face {
//       font-family: ""poppins", sans-serif";
//       src: url("/assets/fonts/Gotham-Narrow/GothamNarrow-Medium.otf");
//       font-weight: 500;
//    }
//    @font-face {
//       font-family: ""poppins", sans-serif";
//       src: url("/assets/fonts/Gotham-Narrow/GothamNarrow-Bold.otf");
//       font-weight: 600;
//    }
//    @font-face {
//       font-family: ""poppins", sans-serif";
//       src: url("/assets/fonts/Gotham-Narrow/GothamNarrow-Bold.otf");
//       font-weight: 800;
//    }
// `;

export { fontFamily };
